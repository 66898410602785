import Meta from '../components/Meta'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const EccJunior = () => {
  const [displayCourse, setDisplayCourse] = useState('幼児クラス-1')

  return (
    <div className="w-full mb-20">
      <Meta
        title="ECCジュニアBS幕張ベイタウン教室"
        description="For The Future. 英語が楽しく学べるからしっかり身に付く。未来が広がる。"
        keywords="ECCジュニアBS幕張ベイタウン教室, 幕張 ECCジュニア, 海浜幕張 英会話"
      />
      <div className="w-full flex flex-col lg:flex-row h-[400px] lg:h-[500px] mb-10 lg:mb-14">
        <div className="order-1 lg:order-first basis-1/3 bg-yellow-300 flex flex-col items-center justify-center">
          <h2 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-white border-b border-white pb-2 mb-3 lg:mb-5 inline-block relative">
            ECC Junior
            <img
              className="absolute -top-16 -left-24 lg:-top-[90px] lg:-left-12 w-24"
              src="images/happy_icon.png"
              alt=""
            />
          </h2>
          <h3 className="text-sm px-5 md:text-base lg:text-lg bg-white py-1 rounded-full text-center">
            ECCジュニアBS幕張ベイタウン教室
          </h3>
        </div>
        <div className="flex-1 bg-top-children bg-cover bg-center"></div>
      </div>

      <div className="flex flex-col items-center mb-20">
        <div className="container flex flex-col gap-5 md:flex-row items-center justify-center mb-10">
          <img
            className="w-full md:w-1/3"
            src="/images/banner_0314.jpg"
            alt=""
          />
          <img
            className="w-full md:w-1/3 border border-gray-300"
            src="/images/banner_0312.jpg"
            alt=""
          />
          <img className="w-full md:w-1/3" src="/images/eiken.png" alt="" />
        </div>
        <Link
          to="https://reserva.be/815"
          target="_blank"
          rel="noopener noreferrer"
          className="flex-1 flex items-center justify-center bg-teal-500 hover:bg-[#14aab8] text-white
            cursor-pointer rounded py-4 w-64"
        >
          無料体験 ご予約はこちら
        </Link>
      </div>

      <h3 className="container text-center text-2xl border-b border-neutral-300 py-3 mb-10">
        YouTubeチャンネル
      </h3>
      <div className="container flex flex-col md:flex-row gap-5 mb-20 lg:mb-32">
        <div className="youtube w-full">
          <iframe
            className="youtube-16-9 mb-2"
            src="https://www.youtube.com/embed/t7YioAczqe4?si=x89FZacRCbF5FGJr"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <span>えいごオリンピック2019</span>
        </div>
        <div className="youtube w-full">
          <iframe
            className="youtube-16-9 mb-2"
            src="https://www.youtube.com/embed/d0fhBa7goHk?si=v_8qb9LQgvdNLsRe"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <span>ブリティシュヒルズ 英語夏合宿</span>
        </div>
        <div className="youtube w-full">
          <iframe
            className="youtube-16-9 mb-2"
            src="https://www.youtube.com/embed/frR4ELpbilo?si=U_ogXBqLqDE-3ufI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <span>約7000分の初オンラインレッスン実施</span>
        </div>
      </div>

      {/* 各コースボタン */}
      <div className="container mb-10">
        <h3 className="text-center text-2xl border-b border-neutral-300 py-3 mb-10">
          コース紹介
        </h3>
        <div className="w-full h-72 bg-course-image bg-cover bg-center mb-10"></div>
        <p className="text-center mb-10">
          各コースの詳細は予約サイトでご確認いただけます。ご予約は下記予約サイトボタンまたは無料体験予約カレンダーからお進みください。
        </p>
        <ul className="ecc-course w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          <li
            className="category-1"
            onClick={() => setDisplayCourse('幼児クラス-1')}
          >
            幼児クラス<span className="text-sm">（2・3歳児）</span>
          </li>
          {/* スマホ用 */}
          {displayCourse === '幼児クラス-1' && (
            <li className="course-description md:hidden">
              <>
                <p>幼児の「学びの土台」と「生きる力」を育む。</p>
                <p>
                  小学校入学前の幼児にとって、「“知りたい”という気持ち」や、「学ぶことへの興味の有無」は、とても重要です。このことは今後の学習において、身につく質や量に大きく影響します。
                </p>
                <p>
                  幼児の発達心理学を修得したECCジュニアの講師が幼児の心や知能の発達に応じて、「“知りたい”という気持ち」を十分引き出し「学ぶための土台」を築きます。
                </p>
                <p>
                  英語・英会話コースでは、「あそび」を通して楽しみながら英語に触れていきます。
                </p>
                <p>
                  まなびのさんぽコースでは、ひとつのテーマを通して、幼児の知的好奇心を引き出しながら、さまざまな分野の学習に取り組みます。また、学習を通して人との関わりを広げ「生きる力」も育みます。
                </p>
              </>
            </li>
          )}
          <li
            className="category-1"
            onClick={() => setDisplayCourse('幼児クラス-2')}
          >
            幼児クラス<span className="text-sm">（4・5歳児）</span>
          </li>
          {/* スマホ用 */}
          {displayCourse === '幼児クラス-2' && (
            <li className="course-description md:hidden">
              <>
                <p>
                  遊びを通して自然に英語を身につける楽しさいっぱいのレッスン。
                </p>
                <p>
                  ４･５歳児の生活は、「遊び」がメイン。興味を引くものには驚異的な集中力を発揮します。しかし反面、飽きっぽさも兼ね備えています。
                </p>
                <p>
                  英語・英会話コースでは、バラエティ豊かな遊びの要素を取り入れ、楽しく英語を学習します。
                </p>
                <p>
                  まなびのさんぽコースでは、ひとつのテーマを通して、幼児の知的好奇心を引き出しながら、さまざまな分野の学習に取り組みます。また、学習を通して人との関わりを広げ「生きる力」も育みます。
                </p>
              </>
            </li>
          )}
          <li
            className="category-1"
            onClick={() => setDisplayCourse('小学生クラス-1')}
          >
            小学生クラス<span className="text-sm">（1・2・3年）</span>
          </li>
          {/* スマホ用 */}
          {displayCourse === '小学生クラス-1' && (
            <li className="course-description md:hidden">
              <>
                <p>
                  子どもの特性を活かしたアクティブなレッスンで、大量の英語に触れ、考える力も養います。
                </p>
                <p>
                  1学期は楽しいアクティビティを通して文型を導入し、2・3学期はテキストを使って理解・定着させていきます。自分のことや身の回りのことについて、聞いたり話したりしながら大量の英語に触れ、英語の「基礎体力」を身につけます。さらにCLIL（クリル）学習を通して、英語力はもちろん思考力・実践力などの21世紀型能力を養成。
                </p>
                <p>
                  子どもたちが喜ぶ美しい映像や躍動感のある音声、クイズ、遊びなどをふんだんに盛り込んだレッスンで、学習効果を高めます。
                </p>
              </>
            </li>
          )}
          <li
            className="category-1"
            onClick={() => setDisplayCourse('小学生クラス-2')}
          >
            小学生クラス<span className="text-sm">（4・5・6年）</span>
          </li>
          {/* スマホ用 */}
          {displayCourse === '小学生クラス-2' && (
            <li className="course-description md:hidden">
              <>
                <p>英語の4技能と思考力を磨き21世紀型スキルを養成する。</p>
                <p>
                  英語力のみならず"グローバルに活躍できる"コミュニケーション能力を養成します。
                </p>
                <p>
                  小学4～6年生の子どもたちは、社会性が発達し、物事を概念的に理解する力や自分の思考を客観視する力（メタ認知）も備わってきます。そのような能力を生かして最大限の効果を上げるために、CLIL（クリル）＝「内容言語統合型学習」を導入します。CLILを活用したレッスンでは、子どもの発達段階に合ったテーマやトピックを取り上げ、英語で学びながら「21世紀型スキル」を養成していきます。また、レッスンは予習ベースで進められ、「グローバル・コミュニケーション能力」の基盤となる基礎英語力を着実に身につけさせます。
                </p>
                <p>
                  子どもたちが主体的に学ぶ意欲や態度を身につけ、継続して学習することができるさまざまなツールを用意して、「自律学習習慣」の確立を手助けします。生涯学習社会と言われる現代では、この自律学習習慣を身につけることが将来に渡っての財産となります。
                </p>
              </>
            </li>
          )}
          <li
            className="category-1"
            onClick={() => setDisplayCourse('中学生クラス')}
          >
            中学生クラス
          </li>
          {/* スマホ用 */}
          {displayCourse === '中学生クラス' && (
            <li className="course-description md:hidden">
              <>
                <p>徹底した大量トレーニングにより一生ものの英語力を目指す。</p>
                <p>
                  中学3年間は、英語学習にとって最重要時期です。中学英語をマスターしさえすれば日常会話に不自由しないと言われるほど、単語･構文･表現の重要なエッセンスがすべてこの3年間に詰まっています。
                </p>
                <p>
                  中学スーパー英語クラスでは、文法や文型など文部科学省に定められた事項を確実に学習しながら、独自の徹底した大量トレーニングを行います。中学3年間で身につけなければならない語彙･表現･文法を深く理解し、確実に覚え、一生ものの英語力の習得を目指します。
                </p>
              </>
            </li>
          )}
          <li
            className="category-2"
            onClick={() => setDisplayCourse('高校生クラス')}
          >
            高校生クラス
          </li>
          {/* スマホ用 */}
          {displayCourse === '高校生クラス' && (
            <li className="course-description md:hidden">
              <>
                <p>
                  高校の英文法をマスター。「英語力」だけでなく「話題力」を身につける。
                </p>
                <p>
                  高校で扱う英文法をはじめ、英検<span>&#174;</span>
                  ･大学入試·TOEIC
                  <span>&#174;</span>
                  L&R/S&Wなどにも対応できる力を養成します。高度な英語運用能力を目指し、速読トレーニングや英語でのディスカッションを実施。
                </p>
                <p>
                  厳選されたトピックを扱った教材で、「英語力」だけでなく真の国際人としての「話題力」を身につけます。
                </p>
                <p className="text-xs">
                  ※TOEICはエデュケーショナル・テスティング・サービス（ETS）の登録商標です。このウェブサイトはETSの検討を受け、またはその承認を得たものではありません
                </p>
              </>
            </li>
          )}
          <li
            className="category-2"
            onClick={() => setDisplayCourse('大人クラス-1')}
          >
            大人<span className="text-sm">（New Master Plan）</span>
          </li>
          {/* スマホ用 */}
          {displayCourse === '大人クラス-1' && (
            <li className="course-description md:hidden">
              <>
                <p>
                  高校の英文法をマスター。「英語力」だけでなく「話題力」を身につける。
                </p>
                <p>
                  高校で扱う英文法をはじめ、英検<span>&#174;</span>
                  ･大学入試·TOEIC
                  <span>&#174;</span>
                  L&R/S&Wなどにも対応できる力を養成します。高度な英語運用能力を目指し、速読トレーニングや英語でのディスカッションを実施。
                </p>
                <p>
                  厳選されたトピックを扱った教材で、「英語力」だけでなく真の国際人としての「話題力」を身につけます。
                </p>
                <p className="text-xs">
                  ※TOEICはエデュケーショナル・テスティング・サービス（ETS）の登録商標です。このウェブサイトはETSの検討を受け、またはその承認を得たものではありません
                </p>
              </>
            </li>
          )}
          <li
            className="category-2"
            onClick={() => setDisplayCourse('大人クラス-2')}
          >
            大人
            <span className="text-sm">（英会話セレクトプラン）</span>
          </li>
          {/* スマホ用 */}
          {displayCourse === '大人クラス-2' && (
            <li className="course-description md:hidden">
              <>
                <p>クラスの興味に応じた話題を選んで学習</p>
                <p>
                  ショッピングや映画、本、観光地など、実際に役立つ興味深いテーマで構成された教材を使用。クラスの興味に応じたテーマを選んで学習します。基本表現以外にも、役立つ表現やおしゃれな言い回しなども盛り込み、英語力だけでなく、英語のセンスもブラッシュアップします。
                </p>
              </>
            </li>
          )}
          <li
            className="category-2"
            onClick={() => setDisplayCourse('大人クラス-3')}
          >
            大人<span className="text-sm">（わくわく英語）</span>
          </li>
          {/* スマホ用 */}
          {displayCourse === '大人クラス-3' && (
            <li className="course-description md:hidden">
              <>
                <p>おとなの英会話。簡単な英会話を通じて “心はずむ交流” を。</p>
                <p>
                  海外旅行や外国人観光客へのおもてなしに使える英会話など、気軽に楽しく学べる“大人のための英会話”クラスです。先生や生徒さんたちとの交流を中心に、有意義な時間を過ごしていただけます。
                </p>
              </>
            </li>
          )}
          <li
            className="category-2"
            onClick={() => setDisplayCourse('英検対策-1')}
          >
            英検®対策コース
          </li>
          {/* スマホ用 */}
          {displayCourse === '英検対策-1' && (
            <li className="course-description md:hidden">
              <>
                <p>対象：3級、4級、5級、5級準備</p>
                <p>音声ペンを使って自分のペースで無理なく英検®合格を目指す。</p>
                <p>
                  絵本教材と録音機能つきの音声ペンを使って、自学自習できるコースです。英語を読みたい、覚えたいという気持ちを育みながら、スピーキング力やリスニング力といった総合的な英語力を身につけ、英検®合格を目指します。
                </p>
              </>
            </li>
          )}
          <li
            className="category-2"
            onClick={() => setDisplayCourse('英検対策-2')}
          >
            英検®直前対策コース
          </li>
          {/* スマホ用 */}
          {displayCourse === '英検対策-2' && (
            <li className="course-description md:hidden">
              <>
                <p>対象：2級、準2級、3級、4級、5級</p>
                <p>
                  年3回の英検<span>&#174;</span>
                  実施に合わせた、短期コースです。無学年生で、2024年出題内容改訂にも対応しています。
                </p>
              </>
            </li>
          )}
          <li
            className="category-3"
            onClick={() => setDisplayCourse('すらすらマイプリントコース')}
          >
            すらすらマイプリントコース
          </li>
          {/* スマホ用 */}
          {displayCourse === 'すらすらマイプリントコース' && (
            <li className="course-description md:hidden">
              <>
                <p>対象：小学1年生～6年生</p>
                <p>
                  一人ひとりの学習目標に合わせたカリキュラムで、算・国・理・社の学校の授業をマスターすることを目指します。
                </p>
                <p>
                  学習指導要領に沿った、各学年の教科学習内容を自学自習で取り組み、学習の土台をしっかり固めます。
                </p>
              </>
            </li>
          )}
          <li
            className="category-3"
            onClick={() => setDisplayCourse('かんじコース')}
          >
            かんじコース
          </li>
          {/* スマホ用 */}
          {displayCourse === 'かんじコース' && (
            <li className="course-description md:hidden">
              <>
                <p>対象：幼児（4歳児）～社会人レベル</p>
                <p>
                  すべての学習に役立つ、ことばの基礎を養成します。漢検に対応した内容は、確実に感じが覚えられるだけでなく、国語力の向上にもつながります。
                </p>
              </>
            </li>
          )}
          <li
            className="category-3"
            onClick={() => setDisplayCourse('計算コース')}
          >
            計算コース
          </li>
          {/* スマホ用 */}
          {displayCourse === '計算コース' && (
            <li className="course-description md:hidden">
              <>
                <p>対象：幼児（4歳児）～小学6年生</p>
                <p>
                  小学校6年間で学習する計算力を、年齢学年に関係なく身につけることができます。
                </p>
                <p>
                  計算力を身につけることで、算数に対する自信を深め、創造的な知性へとつながっていきます。
                </p>
              </>
            </li>
          )}
        </ul>
      </div>

      {/* コースタイトル */}
      <div className="hidden md:block">
        <div className="container mb-10 flex flex-col md:flex-row gap-5 lg:gap-10 md:min-h-48">
          <div className="basis-1/5 border border-neutral-500 flex items-center justify-center p-2 text-center">
            {displayCourse === '幼児クラス-1' ? (
              <span>
                幼児クラス
                <br />
                （2・3歳児）
              </span>
            ) : displayCourse === '幼児クラス-2' ? (
              <span>
                幼児クラス
                <br />
                （4・5歳児）
              </span>
            ) : displayCourse === '小学生クラス-1' ? (
              <span>
                小学生クラス
                <br />
                （1・2・3年）
              </span>
            ) : displayCourse === '小学生クラス-2' ? (
              <span>
                小学生クラス
                <br />
                （4・5・6年）
              </span>
            ) : displayCourse === '中学生クラス' ? (
              '中学生クラス'
            ) : displayCourse === '高校生クラス' ? (
              '高校生クラス'
            ) : displayCourse === '大人クラス-1' ? (
              <span>
                大人クラス
                <br />
                （New Master Plan）
              </span>
            ) : displayCourse === '大人クラス-2' ? (
              <span>
                大人クラス
                <br />
                （英会話セレクトプラン）
              </span>
            ) : displayCourse === '大人クラス-3' ? (
              <span>
                大人クラス
                <br />
                （わくわく英語）
              </span>
            ) : displayCourse === '英検対策-1' ? (
              <span>英検®対策コース</span>
            ) : displayCourse === '英検対策-2' ? (
              <span>英検®直前対策コース</span>
            ) : displayCourse === 'すらすらマイプリントコース' ? (
              <span>すらすらマイプリントコース</span>
            ) : displayCourse === 'かんじコース' ? (
              <span>かんじコース</span>
            ) : displayCourse === '計算コース' ? (
              <span>計算コース</span>
            ) : (
              <span>
                幼児クラス
                <br />
                （2・3歳児）
              </span>
            )}
          </div>

          {/* コース内容説明 */}
          <div className="flex-1 course-description">
            {displayCourse === '幼児クラス-1' ? (
              <>
                <p>幼児の「学びの土台」と「生きる力」を育む。</p>
                <p>
                  小学校入学前の幼児にとって、「“知りたい”という気持ち」や、「学ぶことへの興味の有無」は、とても重要です。このことは今後の学習において、身につく質や量に大きく影響します。
                </p>
                <p>
                  幼児の発達心理学を修得したECCジュニアの講師が幼児の心や知能の発達に応じて、「“知りたい”という気持ち」を十分引き出し「学ぶための土台」を築きます。
                </p>
                <p>
                  英語・英会話コースでは、「あそび」を通して楽しみながら英語に触れていきます。
                </p>
                <p>
                  まなびのさんぽコースでは、ひとつのテーマを通して、幼児の知的好奇心を引き出しながら、さまざまな分野の学習に取り組みます。また、学習を通して人との関わりを広げ「生きる力」も育みます。
                </p>
              </>
            ) : displayCourse === '幼児クラス-2' ? (
              <>
                <p>
                  遊びを通して自然に英語を身につける楽しさいっぱいのレッスン。
                </p>
                <p>
                  ４･５歳児の生活は、「遊び」がメイン。興味を引くものには驚異的な集中力を発揮します。しかし反面、飽きっぽさも兼ね備えています。
                </p>
                <p>
                  英語・英会話コースでは、バラエティ豊かな遊びの要素を取り入れ、楽しく英語を学習します。
                </p>
                <p>
                  まなびのさんぽコースでは、ひとつのテーマを通して、幼児の知的好奇心を引き出しながら、さまざまな分野の学習に取り組みます。また、学習を通して人との関わりを広げ「生きる力」も育みます。
                </p>
              </>
            ) : displayCourse === '小学生クラス-1' ? (
              <>
                <p>
                  子どもの特性を活かしたアクティブなレッスンで、大量の英語に触れ、考える力も養います。
                </p>
                <p>
                  1学期は楽しいアクティビティを通して文型を導入し、2・3学期はテキストを使って理解・定着させていきます。自分のことや身の回りのことについて、聞いたり話したりしながら大量の英語に触れ、英語の「基礎体力」を身につけます。さらにCLIL（クリル）学習を通して、英語力はもちろん思考力・実践力などの21世紀型能力を養成。
                </p>
                <p>
                  子どもたちが喜ぶ美しい映像や躍動感のある音声、クイズ、遊びなどをふんだんに盛り込んだレッスンで、学習効果を高めます。
                </p>
              </>
            ) : displayCourse === '小学生クラス-2' ? (
              <>
                <p>英語の4技能と思考力を磨き21世紀型スキルを養成する。</p>
                <p>
                  英語力のみならず"グローバルに活躍できる"コミュニケーション能力を養成します。
                </p>
                <p>
                  小学4～6年生の子どもたちは、社会性が発達し、物事を概念的に理解する力や自分の思考を客観視する力（メタ認知）も備わってきます。そのような能力を生かして最大限の効果を上げるために、CLIL（クリル）＝「内容言語統合型学習」を導入します。CLILを活用したレッスンでは、子どもの発達段階に合ったテーマやトピックを取り上げ、英語で学びながら「21世紀型スキル」を養成していきます。また、レッスンは予習ベースで進められ、「グローバル・コミュニケーション能力」の基盤となる基礎英語力を着実に身につけさせます。
                </p>
                <p>
                  子どもたちが主体的に学ぶ意欲や態度を身につけ、継続して学習することができるさまざまなツールを用意して、「自律学習習慣」の確立を手助けします。生涯学習社会と言われる現代では、この自律学習習慣を身につけることが将来に渡っての財産となります。
                </p>
              </>
            ) : displayCourse === '中学生クラス' ? (
              <>
                <p>徹底した大量トレーニングにより一生ものの英語力を目指す。</p>
                <p>
                  中学3年間は、英語学習にとって最重要時期です。中学英語をマスターしさえすれば日常会話に不自由しないと言われるほど、単語･構文･表現の重要なエッセンスがすべてこの3年間に詰まっています。
                </p>
                <p>
                  中学スーパー英語クラスでは、文法や文型など文部科学省に定められた事項を確実に学習しながら、独自の徹底した大量トレーニングを行います。中学3年間で身につけなければならない語彙･表現･文法を深く理解し、確実に覚え、一生ものの英語力の習得を目指します。
                </p>
              </>
            ) : displayCourse === '高校生クラス' ? (
              <>
                <p>
                  高校の英文法をマスター。「英語力」だけでなく「話題力」を身につける。
                </p>
                <p>
                  高校で扱う英文法をはじめ、英検<span>&#174;</span>
                  ･大学入試·TOEIC
                  <span>&#174;</span>
                  L&R/S&Wなどにも対応できる力を養成します。高度な英語運用能力を目指し、速読トレーニングや英語でのディスカッションを実施。
                </p>
                <p>
                  厳選されたトピックを扱った教材で、「英語力」だけでなく真の国際人としての「話題力」を身につけます。
                </p>
                <p className="text-xs mt-5">
                  ※TOEICはエデュケーショナル・テスティング・サービス（ETS）の登録商標です。このウェブサイトはETSの検討を受け、またはその承認を得たものではありません
                </p>
              </>
            ) : displayCourse === '大人クラス-1' ? (
              <>
                <p>
                  高校の英文法をマスター。「英語力」だけでなく「話題力」を身につける。
                </p>
                <p>
                  高校で扱う英文法をはじめ、英検<span>&#174;</span>
                  ･大学入試·TOEIC
                  <span>&#174;</span>
                  L&R/S&Wなどにも対応できる力を養成します。高度な英語運用能力を目指し、速読トレーニングや英語でのディスカッションを実施。
                </p>
                <p>
                  厳選されたトピックを扱った教材で、「英語力」だけでなく真の国際人としての「話題力」を身につけます。
                </p>
                <p className="text-xs mt-5">
                  ※TOEICはエデュケーショナル・テスティング・サービス（ETS）の登録商標です。このウェブサイトはETSの検討を受け、またはその承認を得たものではありません
                </p>
              </>
            ) : displayCourse === '大人クラス-2' ? (
              <>
                <p>クラスの興味に応じた話題を選んで学習</p>
                <p>
                  ショッピングや映画、本、観光地など、実際に役立つ興味深いテーマで構成された教材を使用。クラスの興味に応じたテーマを選んで学習します。基本表現以外にも、役立つ表現やおしゃれな言い回しなども盛り込み、英語力だけでなく、英語のセンスもブラッシュアップします。
                </p>
              </>
            ) : displayCourse === '大人クラス-3' ? (
              <>
                <p>おとなの英会話。簡単な英会話を通じて “心はずむ交流” を。</p>
                <p>
                  海外旅行や外国人観光客へのおもてなしに使える英会話など、気軽に楽しく学べる“大人のための英会話”クラスです。先生や生徒さんたちとの交流を中心に、有意義な時間を過ごしていただけます。
                </p>
              </>
            ) : displayCourse === '英検対策-1' ? (
              <>
                <p>対象：3級、4級、5級、5級準備</p>
                <p>音声ペンを使って自分のペースで無理なく英検®合格を目指す。</p>
                <p>
                  絵本教材と録音機能つきの音声ペンを使って、自学自習できるコースです。英語を読みたい、覚えたいという気持ちを育みながら、スピーキング力やリスニング力といった総合的な英語力を身につけ、英検®合格を目指します。
                </p>
              </>
            ) : displayCourse === '英検対策-2' ? (
              <>
                <p>対象：2級、準2級、3級、4級、5級</p>
                <p>
                  年3回の英検<span>&#174;</span>
                  実施に合わせた、短期コースです。無学年生で、2024年出題内容改訂にも対応しています。
                </p>
              </>
            ) : displayCourse === 'すらすらマイプリントコース' ? (
              <>
                <p>対象：小学1年生～6年生</p>
                <p>
                  一人ひとりの学習目標に合わせたカリキュラムで、算・国・理・社の学校の授業をマスターすることを目指します。
                </p>
                <p>
                  学習指導要領に沿った、各学年の教科学習内容を自学自習で取り組み、学習の土台をしっかり固めます。
                </p>
              </>
            ) : displayCourse === 'かんじコース' ? (
              <>
                <p>対象：幼児（4歳児）～社会人レベル</p>
                <p>
                  すべての学習に役立つ、ことばの基礎を養成します。漢検に対応した内容は、確実に感じが覚えられるだけでなく、国語力の向上にもつながります。
                </p>
              </>
            ) : displayCourse === '計算コース' ? (
              <>
                <p>対象：幼児（4歳児）～小学6年生</p>
                <p>
                  小学校6年間で学習する計算力を、年齢学年に関係なく身につけることができます。
                </p>
                <p>
                  計算力を身につけることで、算数に対する自信を深め、創造的な知性へとつながっていきます。
                </p>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <div className="container mb-5 flex flex-col md:flex-row gap-5">
        <Link
          to="/ecc-contact"
          className="menu-list flex-1 bg-amber-500 hover:bg-[#f5880b] py-3 text-center text-lg cursor-pointer transition-all text-white rounded"
        >
          教室へお問い合わせ
        </Link>
        <Link
          to="https://reserva.be/815"
          target="_blank"
          rel="noopener noreferrer"
          className="flex-1 flex items-center justify-center bg-teal-500 hover:bg-[#14aab8] text-white
          cursor-pointer rounded py-4"
        >
          各種予約サイト
        </Link>
        <Link
          to="https://lin.ee/loPdOzn"
          target="_blank"
          rel="noopener noreferrer"
          className="flex-1 flex items-center justify-center bg-[#06C755]
          cursor-pointer rounded py-2"
        >
          <img className="w-10" src="images/LINE_Brand_icon.png" alt="Line" />
          <p className="text-center text-lg text-white">LINEでご相談</p>
        </Link>
      </div>
      <div className="container mb-20 lg:mb-32">
        <p className="text-xs">
          ※英検&#174;は、公益財団法人 日本英語検定協会の登録商標です。
          <br />
          ※このコンテンツは、公益財団法人
          日本英語検定協会の承認や推奨、その他の検討を受けたものではありません。
        </p>
      </div>

      <div className="container mb-20" id="booking">
        <div className="mb-10">
          <h3 className="text-center text-2xl border-b border-neutral-300 py-3 mb-5">
            予約カレンダー
          </h3>
          <div className="flex justify-center">
            <Link
              to="https://www.eccjr.co.jp/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center hover:bg-gray-100 border border-gray-500
            cursor-pointer rounded py-4 px-5"
            >
              <img
                src="/images/ecc-junior-logo.svg"
                className="w-28 mr-5"
                alt=""
              />
              ウェブサイトはこちらから
            </Link>
          </div>
        </div>
        <iframe
          className="w-full min-h-screen"
          src="https://reserva.be/815/reserve?simple=3"
        ></iframe>
      </div>

      <div className="container lg:mb-10">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
          <div>
            <img src="/images/1217_christmas-market.png" alt="" />
          </div>
          <div>
            <img src="/images/1217_volunteer.png" alt="" />
          </div>
        </div>
      </div>

      <div className="container lg:mb-10">
        <div className="grid grid-cols-2 gap-5 md:grid-cols-4">
          <div>
            <img
              src="/images/speech_contest_1130.jpg"
              alt="中学生スピーチコンテスト"
            />
          </div>
          <div>
            <img src="/images/christmas_01.png" alt="" />
          </div>
          <div>
            <img src="/images/christmas_02.jpg" alt="" />
          </div>
          <div>
            <img src="/images/Costume WEEK.png" alt="" />
          </div>
          <div>
            <img src="/images/IMG_1742.jpg" alt="" />
          </div>
          <div className="border">
            <img src="/images/IMG_1741.jpg" alt="" />
          </div>
          <div className="border">
            <img src="/images/IMG_1740.jpg" alt="" />
          </div>
          <div className="border">
            <img src="/images/IMG_1739.jpg" alt="" />
          </div>
          <div className="border">
            <img src="/images/HALLOween CHallenge.png" alt="" />
          </div>
          <div>
            <img src="/images/IMG_9657.JPG" alt="" />
          </div>
          <div className="border">
            <img src="/images/IMG_9658.JPG" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EccJunior
